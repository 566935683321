<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="header-div">
      <div class="signature-document-title-container vasion-page-title-black" style="font-size: 20px; margin: 4px 0px 4px 10px;">
        {{ document.DisplayName }}
      </div>
      <div class="save-buttons">
        <VasionButton
          id="btnCancel"
          name="btnCancel"
          title="Cancel"
          classProp="secondary-grey"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="btnSave"
          name="btnSave"
          title="Save"
          classProp="primary"
          :isDisabled="!isDirty || invalidFields.length > 0"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="toolbar-div">
      <!-- PAGINATION -->
      <VasionPager
        :currentPageNumber="currentPageNumber"
        :showPageSize="false"
        :totalPages="pageCount"
        @pageChanged="goToPage"
      />

      <div class="toolbar-divider" />

      <!-- ZOOM IN, ZOOM OUT -->
      <VasionZoomTool />
    </div>
    <div id="body-area">
      <div
        class="document-display"
      >
        <DocumentImage
          ref="mainImage"
          :documentID="documentID"
          :pageNumber="currentPageNumber"
          :width="viewerWidth"
          :height="viewerHeight"
          :fileDetail="document.FileDetail"
          :largeDefaultLoadSize="true"
          :includeProcessedAIPZones="true"
          :context="'AnnotationsAndAIPZones'"
          :aipZones="aipZones"
          :disableMoveAndSaveAnnotations="true"
          :selectedAIP="selectedAIP"
          @imageLoaded="mainImageLoaded"
          @backgroundImageLoaded="setZoom"
        />
      </div>
      <div
        class="field-validation-side"
      >
        <div
          class="field-validation-title pad-bottom"
        >
          Field Validation
        </div>
        <div
          class="pad-bottom"
        >
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedObject"
            width="100%"
            type="plain-list"
            displayName="Name"
            valueName="ObjectID"
            title="OBJECT"
            :dataArray="allObjects"
            :checkCanUpdate="doDirtyObjectCheck"
          >
            {{ slotItem.item.Name }}
          </VasionDropList>
        </div>
        <div v-if="selectedObject">
          <div
            class="pad-bottom"
          >
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedFieldsToHide[selectedObject.ObjectID]"
              ref="objectFieldsToHide"
              width="100%"
              type="check-list"
              displayName="DisplayName"
              valueName="FieldID"
              title="OBJECT FIELDS TO HIDE"
              :dataArray="allFieldsInObject"
              @input="createTable()"
            >
              {{ slotItem.item.DisplayName }}
            </VasionDropList>
          </div>
          <div
          >
            <VasionEditableTable
              class="editable-table"
              :headerColumns="headerColumns"
              :tableRows="tableRows"
              :fixedColumnsSizes="true"
              @rowCellClicked="rowCellClicked"
              @cellValueChanged="cellValueChanged"
              @notValid="tableNotValid"
              @isValid="tableValid"
            />
          </div>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
    <VasionAsynchronousConfirmDialog ref="confirm" confirmText="Yes" cancelText="No" />
  </div>
</template>

<script>
import VasionAsynchronousConfirmDialog from '@/components/shared/VasionAsynchronousConfirmDialog.vue'
import Loading from 'vue-loading-overlay';
import VasionZoomTool from '@/components/shared/VasionZoomTool.vue';
import DocumentImage from '@/components/document/DocumentImage.vue';
import { testIsGuid } from '@/store/helperModules/common.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'TheFieldValidationView',
  components: {
    Loading,
    VasionZoomTool,
    DocumentImage,
    VasionAsynchronousConfirmDialog,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function() {
    return {
      aipZones: {
        values: [],
        selectedDocumentIndex: -1,
        aipZoneDocumentRanges: {},
      },
      currentPageNumber: 1,
      document: {},
      firstTimeImageLoad: true,
      fieldValidationData: {},
      folderView: null,
      headerColumns: [],
      invalidFields: [],
      isDirty: false,
      isLoading: true,
      loaderBackgroundColor,
      loaderColor,
      localBackRoute: '',
      selectedAIP: '',
      selectedFieldsToHide: [],
      selectedObject: null,
      selectedRowIndex: -1,
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      tableRows: [],
      viewerHeight: 2112,
      viewerWidth: 1632,
    }
  },
  computed: {
    allObjects() {
      let objectsList = []
      if (this?.fieldValidationData?.Objects) {
        for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
          objectsList.push({
            Name: this.fieldValidationData.Objects[i].Name,
            ObjectID: this.fieldValidationData.Objects[i].ID,
            ListID: i,
          })
        }
      }
      return objectsList
    },
    allFieldsInObject() {
      let fieldsList = []
      if (this?.fieldValidationData?.Objects && this?.selectedObject?.ListID >= 0) {
        for (let i = 0; i < this.fieldValidationData.Objects[this.selectedObject.ListID].Fields.length; i++){
          fieldsList.push({
            DisplayName: this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[i].DisplayName,
            FieldID: this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[i].FieldID,
            ListID: i,
          })
        }
      }
      return fieldsList
    },
    custViewID() { return this.$attrs.custViewID },
    documentID() { return this.$attrs.documentID },
    documentIDs() { return this.$attrs.documentIDs },
    folderID() { return this.$attrs.folderID },
    hiddenColumnIDs() { return this.selectedFieldsToHide[this.selectedObject.ObjectID].map(element => element.ListID) },
    pageCount() { return this.document?.FileDetail?.PageCount },
    previousPathIsDocViewer() { return this.$attrs.fromDocViewer },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    selectedObject: function () {
      this.isDirty = false
      this.invalidFields = []
      this.createTable()
    },
  },
  async mounted() {
    this.$root.confirm = this.$refs.confirm;
  },
  async created() {
    this.isLoading = true
    this.localBackRoute = !this.$store.state.document.backRoute ? '/' : this.$store.state.document.backRoute

    await this.refreshFieldValidationData()

    for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
      if (this.fieldValidationData.Documents[0].FormId === this.fieldValidationData.Objects[i].ID){
        this.selectedObject = {
          Name: this.fieldValidationData.Objects[i].Name,
          ObjectID: this.fieldValidationData.Objects[i].ID,
          ListID: 0,
        }
      }

      this.selectedFieldsToHide[this.fieldValidationData.Objects[i].ID] = []
      if (this.folderView?.length >= 0) {
        this.fieldValidationData.Objects[i].Fields.forEach(f => {
          const showField = this.folderView.find(sf => sf.fieldName === f.FieldName)
          if (showField === undefined) {
            this.selectedFieldsToHide[this.fieldValidationData.Objects[i].ID].push({
              DisplayName: f.DisplayName,
              FieldName: f.FieldName,
              FieldID: f.FieldID
            })
          }
        })
      }
    }
    let rangeTally = 0
    for (let i = 0; i < this.fieldValidationData.Documents.length; i++) {
      this.aipZones.aipZoneDocumentRanges[i] = []
      this.fieldValidationData.Documents[i].IndexFormFields.Values.forEach((f) => {
        let color = {}
        switch(getMeanConfidenceColor(f.MeanConfidence)){
          case ("LawnGreen"):
            color = {
              RECT_COLOR_R: "42",
              RECT_COLOR_G: "200",
              RECT_COLOR_B: "20",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          case ("Yellow"):
            color = {
              RECT_COLOR_R: "254",
              RECT_COLOR_G: "172",
              RECT_COLOR_B: "5",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          case ("Red"):
            color = {
              RECT_COLOR_R: "254",
              RECT_COLOR_G: "20",
              RECT_COLOR_B: "20",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
          default:
            color = {
              RECT_COLOR_R: "4",
              RECT_COLOR_G: "20",
              RECT_COLOR_B: "200",
              RECT_COLOR_A: "100",
              disableSave: "True",
            }
            break
        }
        this.aipZones.values.push({
          fill: color,
          ZoneHeight: f.ZoneHeight,
          ZoneWidth: f.ZoneWidth,
          ZoneX: f.ZoneX,
          ZoneY: f.ZoneY,
          Page: f.PageNumber,
          UniqueID: this.generateUniqueID(f.ZoneX, f.ZoneY),
        })
        this.aipZones.aipZoneDocumentRanges[i].push(rangeTally)
        rangeTally += 1
      })
    }

    this.isLoading = false
  },
  methods: {
    async cancel() {
      if (this.previousPathIsDocViewer) {
        await this.$router.go(-1)
        return
      }
      await this.$store.dispatch('document/setBackRoute', '/')
      this.$router.push({ path: this.localBackRoute })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    cellValueChanged({rowData, columnIndex}) { //Updates both this.tableRows and this.fieldValidationData
      this.isDirty = true

      let rowCellData = rowData.Cells[columnIndex]
      rowCellData.isDirty = true
      
      let selectedObjectFieldIndex = this.headerColumns[columnIndex].index
      let fvCellData = rowData.docRef.IndexFormFields.Values[selectedObjectFieldIndex]
      fvCellData.isDirty = true
      const dropListData = Array.isArray(rowCellData.cellData) ? rowCellData.cellData : [rowCellData.cellData]
      switch(this.headerColumns[columnIndex].type){
        case 1:
        case 6:
        case 7:
        case 8:
        case 11:
        case 12:
        case 13:
        case 14: //All Text-like fields:
          rowCellData.valueToSave = rowCellData.cellData
          fvCellData.TextValue = rowCellData.cellData
          fvCellData.ValueToSave = rowCellData.cellData
          break
        case 2: //Drop-list:
          rowCellData.valueToSave = dropListData.map(d => { return d.name }).join(';')
          //The DropDownSelectedValues could be null
          if (!fvCellData.DropDownSelectedValues) {
            fvCellData.DropDownSelectedValues = {}
          }
          fvCellData.DropDownSelectedValues.Values = dropListData.map(element => element.name)
          fvCellData.ValueToSave = dropListData.map(element => element.name).join(';')
          break
        case 3: //Date:
          rowCellData.valueToSave = String(rowCellData.cellData)
          fvCellData.DateValueUTC = rowCellData.cellData
          fvCellData.ValueToSave = rowCellData.cellData
          break
        // case 9: //Checkbox: (not currently supported)
        //   this.tableRows[rowIndex].Cells[columnIndex].valueToSave = !params.cellData
        //   break
        default:
          break
      }
    },
    async rowCellClicked({rowData, columnIndex}) {
      let rowChanged = this.selectedRowIndex != rowData.originalRowIndex
      this.selectedRowIndex = rowData.originalRowIndex
      this.aipZones.selectedDocumentIndex = rowData.originalRowIndex

      //Once fieldIndex reaches 0 in the for loop(s): we have found the field to display as the AIP zone (if there is an AIP zone)
      let fieldIndex =  columnIndex
      //Account for the File Name column
      fieldIndex -= 1
      if (fieldIndex === -1) {
        if (rowChanged || this.selectedAIP) {
          this.selectedAIP = ''
          await this.reloadDocument(rowData.id)
        }
        return
      }

      const origColumn = this.headerColumns[columnIndex].index
      let zoneX = rowData.docRef.IndexFormFields.Values[origColumn].ZoneX
      let zoneY = rowData.docRef.IndexFormFields.Values[origColumn].ZoneY

      const prevAIP = this.selectedAIP
      this.selectedAIP = !zoneX || !zoneY ? '' : this.generateUniqueID(zoneX, zoneY)
      if (rowChanged || prevAIP != this.selectedAIP) {
        await this.reloadDocument(rowData.id)
      }
    },
    clickedOutsideLeave() { this.routeTo = null },
    createTable() {
      this.headerColumns = [{title: 'Name', sortable: true, type: -1, index: -1}]
      this.tableRows = []
      let firstRow = true
      for (let i = 0; i < this.fieldValidationData.Documents.length; i++) {
        if (this.selectedObject.ObjectID === this.fieldValidationData.Documents[i].FormId) {
          let row = {
            id: this.fieldValidationData.Documents[i].DocumentId,
            docRef: this.fieldValidationData.Documents[i],
            originalRowIndex: i,
            Cells: [{
              cellData: this.fieldValidationData.Documents[i].DisplayName,
              canEdit: false,
            }],
          }
          for (let j = 0; j < this.fieldValidationData.Documents[i].IndexFormFields.Values.length; j++) {
            // Filter out Radio and Checkbox fields:
            if (this.fieldValidationData.Documents[i].IndexFormFields.Values[j].Control !== 9 && this.fieldValidationData.Documents[i].IndexFormFields.Values[j].Control !== 10){
              if (!this.selectedFieldsToHide[this.selectedObject.ObjectID]?.find(element => element.FieldID === this.fieldValidationData.Objects[this.selectedObject.ListID].Fields[j].FieldID)){
                const field = this.fieldValidationData.Documents[i].IndexFormFields.Values[j]
                if (firstRow) {
                  this.headerColumns.push({title: field.DisplayName, sortable: true, type: field.Control, index: j})
                }

                const dropdownValues = []
                if (field.DropDownValues && field.DropDownValues.Values && field.DropDownValues.Values.length > 0) {
                  Object.keys(field.DropDownValues.Values).map((innerkey) => {
                    dropdownValues.push({
                      name: field.DropDownValues.Values[innerkey],
                      value: Number(innerkey),
                    })
                    return true
                  })
                }
                

                let dropdownSelectedValues = []
                if (field.DropDownSelectedValues && field.DropDownSelectedValues.Values && field.DropDownSelectedValues.Values.length > 0) {
                  dropdownSelectedValues = field.DropDownSelectedValues.Values.map((value) => {
                    const retObject = {
                      name: value,
                      value: field.DropDownValues.Values.indexOf(value),
                    }

                    return retObject
                  })
                  if (!field.AllowMultiples && dropdownSelectedValues.length > 0) {
                    dropdownSelectedValues = dropdownSelectedValues[0]
                  }
                }
                
                if (field.Control === 3) {
                  field.TextValue = field.DateValueUTC
                }

                const isSignatureFieldWithValue = field.Control === 12 && field.TextValue?.length > 0

                row.Cells.push({
                  backgroundColor: getMeanConfidenceColor(field.MeanConfidence),
                  canEdit: !field.ReadOnly && !isSignatureFieldWithValue,
                  cellData: (field.Control === 2) ? dropdownSelectedValues : field.TextValue,
                  dropdownValues: dropdownValues,
                  fieldName: field.FieldName,
                  hoverText: (field.Control === 2) ? '' : field.TextValue,
                  isDirty: field?.isDirty ? field?.isDirty : false,
                  meanConfidence: field.MeanConfidence,
                  subType: field.Control === 2 && !field.AllowMultiples ? 'plain-list' : 'check-list',
                  valueToSave: field?.ValueToSave ? field.ValueToSave : '',
                })
              }
            }
          }
          // `.push()`` is more reactive, which causes problems when editing a field when that column is used for sorting 
          // this.tableRows.push(row)
          // this is less reactive, allowing the user to edit the field, and save, without the order changing
          // Since we may skip some records form the original data because they don't match the object
          //   we can't use `i`.
          this.tableRows[this.tableRows.length] = row
          firstRow = false
        }
      }
    },
    async doDirtyObjectCheck() {
      if (this.isDirty) {
        this.$refs.objectFieldsToHide.open = false
        let res = await this.$root.confirm.open({
          message: 'Are you sure you want to change the view? Changes have not been saved to the current object.',
        })
        return res
      }
      return true
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    generateUniqueID(zoneX, zoneY) {
      return "AIP Zone" + zoneX + zoneY
    },
    async getDocumentDetails(documentId) {
      if (await testIsGuid(documentId)) {
        const canPerformWFAction = await this.$store.dispatch('workflow/canPerformWFAction', documentId)
        if (canPerformWFAction === 'True') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'workspace')
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        } else if (canPerformWFAction === 'False') {
          this.$store.dispatch('mainViews/changeActiveSubApp', 'storage')
        }
        return this.$store.dispatch('document/getDocumentDetailsByGuid', documentId)
      } else if (this.$route.params.version) {
        return this.$store.dispatch('document/getDocumentDetailsWithVersion', { documentID: documentId, version: this.$route.params.version })
      } else {
        return this.$store.dispatch('document/getDocumentDetails', { documentID: documentId, workflowStepID: this.$route.params.workflowStepId })
      }
    },
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.pageCount) {
        this.currentPageNumber = pageNumber
        this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      }
    },
    mainImageLoaded(imageData) {
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
          // need to flip the bounds
          const temp = this.viewerHeight
          this.viewerHeight = this.viewerWidth
          this.viewerWidth = temp
        }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }
    },
    async refreshFieldValidationData() {
      let payload = {
        DocumentIdString: this.documentIDs.toString(),
        viewerWidth: this.viewerWidth,
        viewerHeight: this.viewerHeight,
      }
      this.fieldValidationData = await this.$store.dispatch('document/getFieldValidationData', payload)

      if (this.selectedObject === null) {
        for (let i = 0; i < this.fieldValidationData.Objects.length; i++) {
          if (this.fieldValidationData.Documents[0].FormId === this.fieldValidationData.Objects[i].ID){
            this.selectedObject = {
              Name: this.fieldValidationData.Objects[i].Name,
              ObjectID: this.fieldValidationData.Objects[i].ID,
              ListID: 0,
            }
          }
        }
      }

      if (this.custViewID !== undefined && this.folderView === null) {
        const folderViewDetails = await this.$store.dispatch('admin/getCustomFolderViewDetailsForID', this.custViewID)
        this.folderView = folderViewDetails?.fields
      }
      else if (this.folderID !== undefined && this.folderView === null) {
        const folderViewDetails = await this.$store.dispatch('admin/getCustomFolderViewDetailsForFolder', this.folderID)
        this.folderView = folderViewDetails?.fields
      }
    },
    async reloadDocument(documentID) {
      this.$store.dispatch('document/uploadedPagesPageNumber', this.currentPageNumber)
      this.document = {}
      let data = await this.getDocumentDetails(documentID)

      if (!data || !data.DocumentId) {
        this.errorLoadingDocument = true
        return
      }

      this.errorLoadingDocument = false
      this.document = data

      this.$store.dispatch('document/getDocumentVersions', this.document.DocumentId)
      this.$store.dispatch('document/getDocumentPriority', this.document.DocumentId)

      this.$store.dispatch('document/setPendingSignatureAnnotationIDsForCurrentUser', this.document.pendingSignatureAnnotationIDsForCurrentUser)
      this.$store.dispatch('document/setPendingSignatureDocumentZones', this.document.pendingSignatureDocumentZones)

      if (this.$route.params.startingPage) {
        this.currentPageNumber = parseInt(this.$route.params.startingPage, 10)
      } else {
        this.currentPageNumber = 1
      }
    },
    async save() {
      const payload = this.tableRows.map((rowData) => {
        return {
          DocumentId: rowData.id,
          FormId: this.selectedObject.ObjectID,
          FieldValues: {
            Values: rowData.Cells.filter(dirty => dirty.isDirty).map(f => {
              return {
                Key: f.fieldName,
                Value: f.valueToSave,
              }
            })
          }
        }
      })
      const result = await this.$store.dispatch('document/saveFieldValidationData', payload)
      if (result) {
        this.isDirty = false
        this.invalidFields = []

        await this.refreshFieldValidationData()
        this.createTable()
        this.snackbarTitle = 'SUCCESS'
        this.snackbarText = 'Document fields saved successfully!'
        this.showSnackbar = true
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = 'There was an error saving the database fields'
        this.showSnackbar = true
        this.snackbarImage = false
      }
    },
    async setZoom() {
      let tempZoomValue = this.zoomValue
      await this.$store.dispatch('document/resetZoomValue')
      if(this.firstTimeImageLoad){
        this.firstTimeImageLoad = false
        if (window.innerWidth <= 416) {
          this.$store.dispatch('document/setZoomValue', .2)
        } else if (window.innerWidth <= 1430) {
          this.$store.dispatch('document/setZoomValue', .3)
        } else if (window.innerWidth <= 1630) {
          this.$store.dispatch('document/setZoomValue', .36)
        } else if (window.innerWidth <= 1870) {
          this.$store.dispatch('document/setZoomValue', .4)
        } else {
          this.$store.dispatch('document/setZoomValue', .44)
        }
      } else {
        this.$store.dispatch('document/setZoomValue', tempZoomValue)
      }
    },
    tableNotValid(value)
    {
      if (!this.invalidFields.includes(value)) {
        this.invalidFields.push(value)
      }
    },
    tableValid(value)
    {
      if (this.invalidFields.indexOf(value) >= 0) {
        this.invalidFields.splice(this.invalidFields.indexOf(value), 1)
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .header-div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .signature-document-title-container {
      flex: auto;
    }

    .save-buttons {
      flex: initial;
      padding-right: 10px;
    }
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 3px;
    padding-right: 100px;

    .toolbar-divider {
      border-left: solid 1px $grey-100;
      height: 24px;
      margin-top: 5px;
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  #body-area {
    display: flex;

    .document-display {
      padding: 16px;
      overflow: auto;
      height: calc(100vh - 162px);
      text-align: center;
      background: #f1f1f1;
      width: 50%;
      max-width: 50%;
    }

    .field-validation-side {
      padding: 20px 20px 0px 20px;
      width: 50%;
      max-width: 50%;

      .field-validation-title {
        font-size: 20px;
        font-family: Archivo;
      }

      .editable-table {
        overflow-x: scroll;
        height: calc(100vh - 386px);
      }
    }

    .pad-bottom {
      padding-bottom: 20px;
    }
  }
</style>
